/**
 * Replaces all instances of "{parameterName}" in the input string with the corresponding value
 * from the parameters object.
 *
 * Example: Input is "I like {animal}" with parameters = {animal: "Cat"} and output is "I like Cat"
 *
 * @param {string} phrase - A string containing placeholders in the form of "{parameterName}".
 * @param {Object} parameters - An object containing parameter values.
 * @returns {string} The phrase with placeholders replaced by parameter values.
 */
export function replaceWithParameters(phrase, parameters) {
  for (const [key, value] of Object.entries(parameters)) {
    const placeholder = `{${key}}`;
    phrase = phrase.replace(new RegExp(placeholder, 'g'), value);
  }
  return phrase;
}

/**
 * Formats our Address Object to one-line full string
 *
 * Example: Piiri tn 14, Tartu linn, Tartu linn, 51007, EE
 *
 * @param {Object} addressObject
 * @returns {string} Constructed address string
 */
export function constructAddressOutput(addressObject) {
  const addressParts = [
    addressObject?.addressFirstLine,
    addressObject?.addressCity,
    addressObject?.addressIndex,
    addressObject?.addressCountry,
  ];

  // Ignore all empty values
  return addressParts.filter((x) => x).join(', ') || addressObject?.searchString;
}
